.Jumbotron.home {
    background-image: url('../components/images/integrity-core-value-freemasonry_2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 50rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-weight: 500;
    }

    &::before {
        content: "";
        width: 100%;
        bottom: 0;
        top: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, .4);
        z-index: 1;
    }

    .innerContent {
        position: relative;
        z-index: 5;
        color: white;
        width: 50rem;
        padding: 0 1rem 0 1.5rem;

    }
}

.ourValues .card-header {
    background-color: #3d5876;

    >h2 {
        color: #ffffff;
        text-align: center;
    }
}

.ourValues .card-body {
    background-color: #e5edf7;
}