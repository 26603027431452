.Jumbotron.history {
    background-image: url('../components/images/header2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 10rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-weight: 500;
    }

    &::before {
        content: "";
        width: 100%;
        bottom: 0;
        top: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, .4);
        z-index: 1;
    }

    .innerContent {
        position: relative;
        z-index: 5;
        color: white;
        width: 50rem;

    }
}