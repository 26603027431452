.header {
    background-color: rgba(13, 37, 72, 0.92);
    background-image: url('../images/tile-blue.jpg');
    color: #ffffff;
    -webkit-box-shadow: 0px -2px 68px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -2px 68px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -2px 68px 0px rgba(0, 0, 0, 0.75);
    position: relative;
    z-index: 5;

    .logo img {
        width: 100%;
        max-width: 12rem;
        display: block;
    }


    .navbar-nav {
        text-align: right;

        a {
            color: #ffffff;
            text-decoration: none;
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-right: 30px;
            padding-left: 30px;

            margin-left: 20px;

            &:hover {
                border-bottom: 2px solid #ffffff;

                cursor: pointer;
            }

            &.active {
                border-bottom: 2px solid #ffffff;
                color: white;
                font-weight: 600;

            }

        }


    }
}

.navbar-toggler {
    color: white;
    background-color: none;
    border: none;
    background-color: white;
}

@media (max-width: 600px) {
    .navbar>.container-fluid {
        flex-direction: row-reverse;
    }

}


.collapse.navbar-collapse {
    ul {
        li {
            line-height: 3rem;
            text-align: center;
        }
    }

    @media (max-width: 600px) {

        ul {
            margin-top: 2rem;

            li {
                line-height: 4.5rem;
                margin-bottom: 10px;

                >a {
                    display: block;
                    margin-left: 0;
                    font-weight: bold;
                    font-size: 1.6rem;
                    border-radius: 10px;

                    &:hover,
                    &.active {
                        background: #ffffff;
                        color: #2f2f2f;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}